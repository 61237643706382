import React from 'react';
import {connect} from 'react-redux';
import strings from '../../utils/strings';

// import './styles.scss';

import {goToNextStep, setTestStepAnswers} from '../../actions/index';

import {TEST_STEPS} from '../../constants';

import Intro from './Intro';
import Tutorial from './Tutorial';
import Test from './Test';

const subSteps = [Intro, Tutorial, Test];

class Ordkjedetesten extends React.Component {
	constructor() {
		super();

		this.handleNextSubstep = this.handleNextSubstep.bind(this);

		this.state = {
			isSubmitting: false,
			currentSubstepIndex: 0,
		};
	}

	handleNextSubstep(results) {
		const {currentSubstepIndex} = this.state;
		const {candidateGrade} = this.props;

		const {onGoToNextStep, onSetTestStepAnswers} = this.props;

		const isLastStep = currentSubstepIndex === subSteps.length - 1;

		if (isLastStep) {
			const resultKeys = Object.keys(results);
			const formattedResults = resultKeys.map((id) => {
				const value = results[id];
				return {
					id,
					value,
				};
			});
			onSetTestStepAnswers(
				TEST_STEPS.ORDKJEDETESTEN,
				formattedResults,
				candidateGrade
			)
				.then(() => {
					if (!this.state.isSubmitting) {
						this.setState({isSubmitting: true});
						onGoToNextStep();
					}
				})
				.catch(() => {
					this.setState({isSubmitting: false});
				});
		} else {
			this.setState({
				currentSubstepIndex: currentSubstepIndex + 1,
			});
			setTimeout(() => {
				window.scrollTo(0, 0);
			});
		}
	}

	render() {
		const {currentSubstepIndex} = this.state;

		const CurrentSubStep = subSteps[currentSubstepIndex];

		return (
			<div>
				<div style={{marginTop:'-100px'}}>
				<h1 className="lit-page-title">{strings.ordkjedetesten}</h1>
				<CurrentSubStep onNextStep={this.handleNextSubstep} />
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {candidateGrade} = state;
	return {
		candidateGrade,
	};
};

const mapDispatchToProps = {
	onGoToNextStep: goToNextStep,
	onSetTestStepAnswers: setTestStepAnswers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Ordkjedetesten);
