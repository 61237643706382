import React from 'react';
import {connect} from 'react-redux';
import strings from '../../utils/strings';

import AudioPlayer from '../../components/AudioPlayer';
import previewVideoNo from './assets/no_skrivhastighet_video.mov';
import previewVideoNn from './assets/nn_skrivhastighet_video.mov';

import previewVideoSe from './assets/se_child_skrivhastighet_video.mp4';
import previewVideoDe from './assets/de_skrivhastighet_video.mp4';

import {VARIATION} from '../../constants';

class Intro extends React.Component {
	constructor() {
		super();

		this.state = {
			removeTestSentence: false,
			showStartBtn:false,
		};

		const variation = VARIATION;
		const language = strings.getLanguage();

		const previewVideoObj = {
			no: previewVideoNo,
			nn: previewVideoNn,
			se: previewVideoSe,
			de: previewVideoDe,
		};
		const assets = {
			data: require(`../../assets/testdata/${variation}/${language}/skrivehastighet-data.json`),
			audio: require(`../../assets/sounds/${variation}/${language}/swscrc3-3.mp3`),
			video: previewVideoObj[language], //TODO: Use different ones for all langs
		};
		this.currentLang = language;
		this.audio = assets.audio;
		this.text = assets.data[0].value;
		this.videoRefView = React.createRef();
		this.video = assets.video;
	}

	render() {
		const {onNextStep} = this.props;

		let videoRef;

		return (
			<div className="">
				<p className="text-left">
					<AudioPlayer
						autoplay
						inline
						onStart={() => {
							const scrollActionTime =
								this.currentLang === 'se' ? 28000 : 31000;

							if (scrollActionTime) {
								setTimeout(() => {
									if (this.videoRefView.current) {
										this.videoRefView.current.scrollIntoView(
											{
												behavior: 'smooth',
												block: 'start',
											}
										);
										videoRef.play();
									}
								}, scrollActionTime);
							}
						}}
						onPause={() => {
							if (!this.state.removeTestSentence) {
								videoRef.play();
							}
						}}
						onComplete={() => {
							if (!this.state.removeTestSentence) {
								this.videoRefView.current.scrollIntoView({
									behavior: 'smooth',
									block: 'start',
								});

								setTimeout(() => {
									this.setState({
										removeTestSentence:true,
										showStartBtn:true
									});
								}, 1000);

								
							}
						}}
						src={this.audio}
					/>
					{strings.skrivehastighetIntro}
				</p>
				{!this.state.removeTestSentence &&
					this.currentLang !== 'de' && (
						<div className="tutorial-text">
							<h3 className="text">
								<strong>{this.text}</strong>
							</h3>
						</div>
					)}

				{!this.state.removeTestSentence && (
					<div className="lit-preview" ref={this.videoRefView}>
						<p className="lit-preview__label">
							{strings.testLooksLikeThis}
						</p>
						<video
							className="lit-preview__video"
							loop
							onClick={() => {
								videoRef.play();
							}}
							ref={(ref) => {
								videoRef = ref;
							}}
							src={this.video}
						/>
					</div>
				)}

				<p className="text-center">{strings.skrivehastighetIntro2}</p>
				<p className="text-left-hint">{strings.skrivehastighetHint}</p>

				{this.state.showStartBtn && (
					<button
					className="lit-btn bg-primary"
					onClick={() => onNextStep()}
				>
					{strings.start}
				</button>
				)}
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Intro);
